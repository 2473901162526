<template>
  <div class="containerx">
    <el-dialog width="30%" title="添加" :visible.sync="show1">
      <el-input v-model="input1"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show1 = false">取 消</el-button>
        <el-button type="primary" @click="confirm1">确 定</el-button>
      </div>
    </el-dialog>
    <div>
      <el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect"
        background-color="#192534" text-color="#fff" active-text-color="#2BDEC9">
        <el-submenu index="1">
          <template slot="title">{{typeName}}</template>
          <el-menu-item index="0">环境设备</el-menu-item>
          <el-menu-item index="1">能源设备</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">{{equipmentName}}</template>
          <el-menu-item :index="item.id.toString()" v-for="(item,index) in hjny_list">{{item.name}}
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
    <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
      <div class="title">安装信息</div>
      <div style="display: flex;">
        <div @click="show1 = true" class="add" style="cursor: pointer;">新增</div>
        <!-- <div class="back">返回</div> -->
      </div>
    </div>
    <div class="table">
      <div class="tr">
        <div class="td1" style="background-color: rgba(43, 201, 222, 0.6);">
          <p>序号</p>
        </div>
        <div class="td2 td" style="background-color: rgba(43, 201, 222, 0.6);">
          <p>信息名称</p>
        </div>
        <div class="td3s td" style="background-color: rgba(43, 201, 222, 0.6);justify-content: center;">
          <p style="margin-right: 2rem;">修改</p>
        </div>
        <div class="td3s td" style="background-color: rgba(43, 201, 222, 0.6);justify-content: center;">
          <p style="margin-right: 2rem;">操作</p>
        </div>
      </div>

      <draggable v-model="tableData" @change="log" :options="{animation:100}">
        <div class="tr" v-for="(item, index) in tableData" :key="item.sort" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="td2 td">
            <p>{{item.parameter}}</p>
          </div>

          <div @click="ed(item)" class="td3s td" style="display: flex;justify-content: center;cursor: pointer;">
            <p style="margin-right: 2rem;color: #00FFFF;">修改</p>
          </div>
          <div @click="del(item)" class="td3s td" style="display: flex;justify-content: center;cursor: pointer;">
            <p style="margin-right: 2rem;color: rgba(250, 105, 105, 1);">删除</p>
          </div>

        </div>
      </draggable>

    </div>

    <!-- 新增弹窗 -->
    <el-dialog title="操作" :visible.sync="dialogFormVisible">
      <el-form :model="form" style="margin-top: 1rem;">
        <el-form-item label="信息名称:" :label-width="formLabelWidth">
          <el-input v-model="form.parameter" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="Form()">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 新增 -->
    <el-dialog title="新增设备" :visible.sync="dialogFormVisible2">
      <el-form :model="form2">
        <el-form-item label="设备名:" :label-width="formLabelWidth">
          <el-input v-model="form2.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-for="(item,index) in form2.parameterList" label="参数:" :label-width="formLabelWidth">
          <el-input style="margin-bottom: 1rem; width: 30%; margin-right: 1rem;" v-model="form2.parameterList[index]"
            autocomplete="off"></el-input><span style="color: red;" @click="shanc(index)">删除</span>
        </el-form-item>
        <el-button type="primary" @click="addc">添加参数</el-button>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="Addgy2()">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    data() {
      return {
        form2: {
          "name": "",
          "parameterList": [
            ''
          ],
          "type": 0
        },
        value: '',
        dialogFormVisible2: false,
        dialogFormVisible: false,
        tableData: [],
        hjny_arr: ['环境设备', '能源设备'],
        hjny_current: 0,
        hjny_list: [],
        hjny_l_c: 0,
        sbId: 0,
        input1: '',
        show1: false,
        form: {
          "id": null,
          "parameter": ""
        },
        formLabelWidth: '12rem',
        activeIndex2: '0',
		typeName: '环境设备',
		equipmentName: '选择设备'
      }
    },
    components: {
      draggable
    },
    mounted() {
      this.getListSblx()
    },
    methods: {
      Addgy2() {
        // this.form2.parameterList = this.Cs.split("，")
        this.form2.type = this.hjny_current
        console.log(this.form2.parameterList, 'this.form2.parameterList!!!');
        // return
        this.$ajax('adsb', 'post', this.form2).then(res => {
          console.log(res);
          if (res.code == 2000) {
            this.$message({
              type: 'success',
              message: res.message
            });
            this.form = {
              "name": "",
              "parameterList": [],
              "type": null
            }
            this.dialogFormVisible2 = false
            this.getListSblx()
          }
        })
      },
      addc() {
        this.form2.parameterList.push('')
      },
      shanc(id) {
        this.form2.parameterList.splice(id, 1)
      },
      xz() {
        console.log(this.value, 'index!!!!!!');
      },
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
        if (keyPath[0] == '1') {
          this.hjny_current = key
		  if(key==0){
			  this.typeName="环境设备"
		  }else{
			  this.typeName="能源设备"
		  }
          this.getListSblx()
        } else {
          if (key == '-999999') {
            this.dialogFormVisible2 = true
          } else {
            this.sbId = key
			this.hjny_list.forEach((equipment,index)=>{
				if(equipment.id ==key){
					this.equipmentName = equipment.name
				}
			})
            this.getXx()
          }

        }
      },
      log(e) {
        console.log(e, "2222");
        console.log(this.tableData);
        this.PX()
      },
      PX() {
        let _this = this
        let narr = []
        let narr1 = []
        for (let i = 0; i < _this.tableData.length; i++) {
          narr1.push(_this.tableData[i].sort)
        }
        this.sortNum(narr1);
        console.log(narr1, 'narr1!!!!');
        for (let i = 0; i < _this.tableData.length; i++) {
          let obj = {
            id: _this.tableData[i].id,
            sort: narr1[i]
          }
          narr.push(obj)
        }

        console.log(narr, narr1, 'narr!!!!!');
        // return
        this.$newPost('/install/info/sort', narr).then(res => {
          console.log(res, 'PX')
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.getListSblx()
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      },
      sortNum(arr) {
        arr.sort(function(a, b) {
          return a - b;
        });
      },
      Form() {
        this.$newPost('/install/info/update', this.form).then(res => {
          console.log(res, '')
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            });
            this.getXx()
            this.dialogFormVisible = false
            this.form = {
              "id": null,
              "parameter": ""
            }
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        })
      },
      ed(item) {
        this.dialogFormVisible = true
        this.form = item
      },
      handleHjny(index) {
        this.hjny_current = index
        this.hjny_l_c = 0
        this.getListSblx()
      },
      handleHjnyL(index, item) {
        this.hjny_l_c = index
        this.sbId = item.id
        this.getXx()
      },
      getXx() {
        this.$newGet('/install/info/list/' + this.sbId).then(res => {
          console.log(res, '设备xx')
          this.tableData = res.data
        })
      },
      // 根据设备类别查询设备类型列表
      getListSblx() {
        this.$newPost('/equipment/type/list/' + this.hjny_current).then(res => {
          console.log(res, '设备类别查询')
          this.hjny_list = res.data
          let narr = {
            id: '-999999',
            name: '添加设备',
            type: this.hjny_list
          }
          this.hjny_list.push(narr)
          this.sbId = res.data[0].id
		  this.equipmentName = res.data[0].name
          this.getXx()
        })
      },
      del(item) {
        console.log(item)
        this.$confirm('确认删除该信息?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$newDelete('/install/info/delete/' + item.id).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              });
              this.getXx()
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          })
        })
      },
      confirm1() {
        if (this.input1 == '') {
          this.$message({
            message: '不能为空',
            type: 'warning'
          });
        } else {
			if(this.sbId=='-999999'){
				this.$message({
				  message: "新增失败，未选择设备类型！",
				  type: 'error'
				});
				return;
			}
          var obj = {
            "equipmentTypeId": this.sbId,
            "parameter": this.input1
          }
          this.$newPost('/install/info/save', obj).then(res => {
            if (res.code == 2000) {
              this.$message({
                message: res.message,
                type: 'success'
              });
              this.show1 = false
              this.getXx()
              this.input1 = ''
            } else {
              this.$message({
                message: res.message,
                type: 'warning'
              });
            }
          })
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .bg1 {
    background-color: rgba(162, 162, 162, 0.1);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.25);
  }

  .containerx {
    color: #ffffff;
    overflow: auto;
    background-color: #192534;
    padding: 1rem 0;
  }

  .btngrp2 {
    margin-left: 1rem;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .item {
      // width: 10rem;
      // height: 4.6rem;
      line-height: 2rem;
      text-align: center;
      // border: 1px solid #2BC9DE;
      font-size: 2rem;
      color: rgba(43, 222, 201, 0.6);
      text-decoration: underline;
      // background: rgba(43, 222, 201, 0.6);
      margin-right: 1.6rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }

    .item-c {
      // width: 10rem;
      // height: 4.6rem;
      line-height: 4.6rem;
      text-align: center;
      color: #2BC9DE;
      text-decoration: underline;
      // border: 1px solid #2BC9DE;
      font-size: 2rem;
      // background-color: rgba(43, 201, 222, 0.6);
      margin-right: 1.6rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }

  .btngrp {
    margin-left: 1rem;
    margin-top: 1rem;
    display: flex;
    align-items: center;

    .btn1 {
      width: 10rem;
      height: 6.4rem;
      line-height: 6.4rem;
      text-align: center;
      background: rgba(43, 201, 222, 0.6);
      font-size: 1.8rem;
      margin-right: 3rem;
      cursor: pointer;
    }

    .btn2 {
      width: 10rem;
      height: 6.4rem;
      line-height: 6.4rem;
      text-align: center;
      background: rgba(43, 222, 201, 0.6);
      font-size: 1.8rem;
      margin-right: 3rem;
      cursor: pointer;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 2rem;
    //margin-top: 2rem;
    height: 5rem;
    line-height: 5rem;
  }

  .add {
    background: #2BC9DE;
    border-radius: 0.5rem;
    width: 6.7rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    font-size: 1.4rem;
    color: #FFFFFF;
    margin-right: 1rem;
  }

  .back {
    border: 1px solid #1CB8B8;
    border-radius: 0.5rem;
    width: 6.7rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    color: rgba(28, 184, 184, 1);
    font-size: 1.4rem;
    margin-right: 2rem;
  }

  .table {
    width: 154.6rem;
    height: 60rem;
    overflow: auto;
    margin: 0 auto;

    .tr {
      display: flex;
      padding: .5rem 0;
      // margin-top: 1rem;

      .td1 {
        font-size: 1.4rem;
        width: 6.6rem;
        height: 4.2rem;
        // margin-right: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .td {
        font-size: 1.4rem;
        height: 4.2rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        // margin-right: 0.4rem;
      }

      .td2 {
        width: 85rem;
      }

      .td3s {
        width: 30rem;
      }

      .td3 {
        width: 22.7rem;
      }

      .tdd {
        border: 1px solid #2BDEC9;
        width: 22.7rem;
        height: 4.2rem;
        margin-right: 0.4rem;
        text-align: center;
        line-height: 4.2rem;
      }
    }

    .tr:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
  }
</style>
